import React, {CSSProperties, ForwardedRef, forwardRef, ImgHTMLAttributes, useEffect, useState} from "react";
import classNames from "classnames";

import "../../types.d.ts";
import {Placeholder} from "./Placeholder";

import {imageWithRatio, imageWrapper, imageWrapperBlock, imageWrapperError} from "./Image.module.css";

type AspectRatios = `${number}/${number}`;

interface IWithDefinedWidthAndHeight {
    width: string;
    height: string;

    ratio?: {
        md?: AspectRatios;
        xs: AspectRatios;
    };
}

interface IWithDefinedRatio {
    width: string | undefined;
    height: string | undefined;

    ratio: {
        md?: AspectRatios;
        xs: AspectRatios;
    };
}

export interface IImageProps {
    src?: string;
    src2x?: string;
    src3x?: string;
    width: string | undefined;
    height: string | undefined;
    alt: string;
    loading?: "lazy";
    className?: string;
    imageClassName?: string;
    imageStyle?: CSSProperties;
    onClick?: React.MouseEventHandler;
    crossOrigin?: ImgHTMLAttributes<HTMLImageElement>["crossOrigin"];
    onError?: () => void;
    fetchPriority?: "high" | "low" | "auto";
}

export const Image = forwardRef((props: IImageProps & (IWithDefinedRatio | IWithDefinedWidthAndHeight), ref: ForwardedRef<HTMLImageElement>) => {
    const {src, src2x, src3x, className, alt, loading, width, height, fetchPriority, ratio, imageClassName} = props;
    const [hasError, setHasError] = useState(false);
    const srcSet = getSrcSet(src, src2x, src3x);

    useEffect(() => {
        setHasError(false);
    }, [src]);

    const onError = () => {
        setHasError(true);
        props.onError?.();
    };

    const hasDimensions = !!height && !!width;
    // When no ratio is provided height and width needs to be provided in case we use fallback
    const ratioFallback = hasError && !ratio && hasDimensions ? `${(parseInt(height) / parseInt(width)) * 100}%` : "auto";

    const ratioXs = ratio?.xs || ratioFallback;
    const ratios = {
        "--ratio-xs": ratioXs,
        "--ratio-md": ratio?.md ? ratio?.md : ratioXs,
        ...(hasError ? {width, height} : {})
    };

    const cn = classNames(imageWrapper, ratio && imageWrapperBlock, hasError && imageWrapperError, className);
    const imageCn = classNames(ratio && imageWithRatio, imageClassName);

    return (
        <div className={cn} style={ratios} onClick={props.onClick}>
            {hasError || !src ? (
                <Placeholder />
            ) : (
                <img
                    ref={ref}
                    src={src}
                    srcSet={srcSet}
                    style={{...ratios, ...props.imageStyle}}
                    className={imageCn}
                    alt={alt}
                    onError={onError}
                    loading={loading}
                    width={width}
                    height={height}
                    fetchpriority={fetchPriority}
                />
            )}
        </div>
    );
});

// Utils
function getSrcSet(src?: string, src2x?: string, src3x?: string) {
    if (src2x && src3x) {
        return `${src}, ${src2x} 2x, ${src3x} 3x`;
    }

    if (src2x) {
        return `${src}, ${src2x} 2x`;
    }

    return src;
}
