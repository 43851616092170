export const rem = (value: number): string => `${value.toFixed(1)}rem`;

// variables defining gaps, padding, margins etc.
export type Size =
    | 0
    | 0.5
    | 1
    | 1.25
    | 1.5
    | 2
    | 2.5
    | 3
    | 3.5
    | 4
    | 4.5
    | 5
    | 6
    | 7
    | 7.5
    | 8
    | 8.5
    | 9
    | 10
    | 11
    | 12
    | 12.5
    | 13
    | 14
    | 15
    | 16
    | 18
    | 20
    | 21
    | 24
    | 25
    | 34;
export const DEFAULT_SIZE = 1;
export const DEFAULT_BASE = 0.8;

export const calculateRemSize = (size: Size = DEFAULT_SIZE, base = DEFAULT_BASE) => rem(size * base);
