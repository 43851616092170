import React, {CSSProperties} from "react";
import {useContext} from "react";
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {backgroundColor, p} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";

import {ModalContext} from "../ModalContext";

interface IModalCloseButtonProps {
    placement?: "left" | "right";
    className?: string;
    fillColor?: string;
    wrapperColor?: string;
    size?: string;
    style?: CSSProperties;
}

export const ModalCloseButton = (props: IModalCloseButtonProps) => {
    const {onModalClose} = useContext(ModalContext);
    const theme = useTheme();

    const size = props.size ?? "2.4";
    const fillColor = props.fillColor ?? theme.colors.secondary;
    const backgroundColorValue = props.wrapperColor ?? "transparent";

    return (
        <CloseIconWrapper
            placement={props.placement}
            role="button"
            onClick={onModalClose}
            className={props.className}
            css={[closeIconStyle, backgroundColor(backgroundColorValue)]}
            style={props.style}
        >
            <CloseIcon size={size} wrapperSize={size} wrapperColor={props.wrapperColor} fill={fillColor} />
        </CloseIconWrapper>
    );
};

const CloseIconWrapper = styled.div<{placement?: "left" | "right"}>`
    ${({placement}) =>
        placement === "left" &&
        css`
            position: absolute;
            top: 0;
            left: 0;
        `};
    ${({placement}) =>
        placement === "right" &&
        css`
            position: absolute;
            top: 0;
            right: 0;
        `};

    ${p(2)};
    align-self: flex-start;
    z-index: 1;
`;

const closeIconStyle = css`
    cursor: pointer;
`;
