import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory, useParams} from "react-router";
import {css} from "@emotion/react";

import {Container} from "@pg-design/grid-module";
import {minHeight, onDesktop} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {useModal} from "@pg-design/modal";
import {Text} from "@pg-design/text-module";
import {kmAppLink, SiteSlug} from "@pg-mono/km-routes";

import {CenterBox} from "../../../components/CenterBox";
import {Modal} from "../../feedback/components/generic_modal/Modal";
import {addNotification} from "../../notifications/state/notifications_slice";
import {IUserProfile} from "../../user/interfaces/IUserProfile";
import {mailingUnsubscribeUser} from "../api/mailing_unsubscribe_user";
import {validateMailingUnsubscribeLink} from "../api/validate_mailing_unsubscribe_link";
import {ActivitySource} from "../constants/ActivitySource";
import {getSiteSlugFromActivitySource} from "../utils/get_site_slug_from_activity_source";

interface IProps {
    userProfile?: IUserProfile;
}

export const MailingUnsubscribeView = (props: IProps) => {
    const {userProfile} = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams<{emailHash: string; verificationHash: string; site: keyof typeof ActivitySource}>();

    const [email, setEmail] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const {shouldModalBeOpened: showModal, closeModal} = useModal({openedByDefault: true});

    useEffect(() => {
        const allowedSite = params.site && ActivitySource[params.site];

        // Only `rynekpierwotny` and `gethome` site params should be handled
        if (allowedSite) {
            validateMailingUnsubscribeLink(params.emailHash, params.verificationHash)
                .then((response) => {
                    if (userProfile) {
                        pushToSubscriptionPage(ActivitySource[params.site]);
                        return;
                    }

                    if (!userProfile && response.email_marketing_consent) {
                        setEmail(response.email);

                        return;
                    }
                })
                .finally(() => setLoading(false));
        } else {
            pushToHomePage();
        }
    }, []);

    const onUnsubscribe = () => {
        setLoading(true);

        mailingUnsubscribeUser(params.emailHash, params.verificationHash, ActivitySource[params.site])
            .then(() => closeModal())
            .catch(() => dispatch(addNotification({type: "failure", content: "Coś poszło nie tak. Spróbuj później."})))
            .finally(() => setLoading(false));
    };

    const pushToHomePage = () => {
        history.push(kmAppLink.site.baseWithParams({site: SiteSlug.KM}));
    };

    const pushToSubscriptionPage = (site: ActivitySource) => {
        const siteSlug = getSiteSlugFromActivitySource(site);
        history.push(kmAppLink.site.user.subscriptionWithParams({site: siteSlug}));
    };

    const loader = (
        <CenterBox css={minHeight("100vh")}>
            <Loader size="lg" />
        </CenterBox>
    );

    if (loading) {
        return loader;
    }

    return (
        <div>
            <Container>
                {showModal ? (
                    loader
                ) : (
                    <CenterBox css={minHeight("100vh")}>
                        <Text variant="headline_0" mb="1.6rem">
                            Przykro nam, że nas opuszczasz
                        </Text>
                        <Text variant="body_copy_0">
                            Szanujemy Twoje zdanie - nie będziemy już wysyłać wiadomości na adres <strong>{email}</strong>
                        </Text>
                    </CenterBox>
                )}
            </Container>

            <Modal isOpen={showModal} onModalClose={pushToHomePage} css={modalStyle}>
                <Modal.Layout.ConfirmCentered
                    title="Czy na pewno chcesz usunąć swój adres e‑mail z naszej bazy?"
                    onConfirmation={onUnsubscribe}
                    onCancel={pushToHomePage}
                    okButtonText="Tak"
                    cancelButtonText="Nie"
                />
            </Modal>
        </div>
    );
};

const modalStyle = css`
    ${onDesktop(css`
        max-width: 66rem;
    `)};
`;
